@import "theme";

$time-color: 0.1s;
$time-background: 5s;

.animate-colors-transition {
  -webkit-transition: all $time-color;
  -moz-transition: all $time-color;
  -ms-transition: all $time-color;
  -o-transition: all $time-color;
  transition: all $time-color;
}

.animate-colors-transition-inherit * {
  -webkit-transition: all $time-color;
  -moz-transition: all $time-color;
  -ms-transition: all $time-color;
  -o-transition: all $time-color;
  transition: all $time-color;
}

.theme-colors * {
  color: var(--text-primary);
  background: var(--background-primary);
}
