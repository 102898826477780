//1. Background (0dp elevation surface overlay): this should be light/dark background
//2. Surface (with 1dp elevation surface overlay)
//3. Primary: the primary colors of the theme, white for light
//4. Secondary
//5. On background
//6. On Surface
//7. On Primary
//8. On Secondary

//shades of colors

$ionfi-teal: #00989F;

$white: #FFFFFF;
$bright-gray: #F8F8FA;

$ionfi-black-opacity-9: rgba(24, 24, 25, 0.9);
$ionfi-black-opacity-5: rgba(24, 24, 25, 0.5);
$ionfi-black-opacity-7: rgba(24, 24, 25, 0.7);
$ionfi-black-opacity-0-16: rgba(86, 87, 88, 0.16);

$ionfi-white-opacity-5: rgba(255,255,255, 0.5);
$ionfi-white-opacity-9: rgba(255,255,255, 0.9);
$ionfi-white-opacity-7: rgba(255,255,255, 0.7);

$ionfi-purple-opacity-0-16: rgba(78, 0, 198, 0.16);
$ionfi-purple-opacity-0-04: rgba(78, 0, 198, 0.04);

$purple-200: #C79BF2;
$purple-300: #B16FED;
$purple-700: #7300D3;
$purple-primary: #9E4AE8;
$purple-rose: #DDC4F7;

$text-primary-opacity-5: $ionfi-black-opacity-5;
$text-primary-opacity-5-dark: $ionfi-white-opacity-5;

$text-primary-opacity-9: $ionfi-black-opacity-9;
$text-primary-opacity-9-dark: $ionfi-white-opacity-9;

$text-primary-opacity-7: $ionfi-black-opacity-7;
$text-primary-opacity-7-dark: $ionfi-white-opacity-7;

//colors for theme
$text-primary: #181819;
$text-primary-dark: #FFFFFF;

$background-primary: #FFFFFF;
$background-primary-dark: #23252B;

$background-gray: #F8F8FA;

$background-secondary: #F5F6FA;
$background-secondary-dark: #1B1C21;

$text-sidebar-highlight: #4E00C6;
$text-sidebar-highlight-dark: #FFFFFF;

$text-sidebar-background-highlight: rgba(78, 0, 198, 0.04);
$text-sidebar-background-highlight-dark: #7300D3;

$background-sidebar: #FFFFFF;
$background-sidebar-dark: #27282E;

$background-sidebar-filter: #F8F8FA;

$border: #E6E7E9;
$border-dark: #27282E;

$purple: #4E00C6;
$purple-dark: #C79BF2;

$button-purple: #4E00C6;
$button-purple-dark: $purple-primary;

$button-white: #F8F8F8;
$button-white-dark: $purple-dark;

$input-text:  #000000;
$input-background: #FFFFFF;

$upload-background: $bright-gray;
$upload-background-dark: $purple-rose;

$upload-background-hover: #E6E7E9;
$upload-background-hover-dark: #d3b3f5;

$download-button-text: rgba(78, 0, 198, 0.9);
$download-button-text-dark: #FFFFFF;

$download-button-background: rgba(78, 0, 198, 0.04);
$download-button-background-dark: $purple-primary;

$download-button-background-focused: rgba(78, 0, 198, 0.16);
$download-button-background-focused-dark: $purple;

$dark-green-opacity-15: rgba(76, 175, 80, 0.15);
$text-green: #4CAF50;

$text-red-opacity-15: rgba(221, 66, 90, 0.15);
$text-red: #DD425A;

$text-yellow-opacity-15: rgba(255, 160, 0, 0.16);
$text-yellow: #FFA000;

$text-blue-opacity-15: rgba(0, 189, 203, 0.15);
$text-blue: #00BDCB;

$text-primary-9: #181819;
$text-primary-9-dark: rgba(24, 24, 25, 0.9);

$braid-background: rgba(25, 155, 225, 0.75);
$braid-text: rgba(255,255,255);

$color-yellow: rgba(200, 200, 0, 1.0);

$colors: (
  --text-primary: ($text-primary, $text-primary-dark),
  --text-opacity-dark: ($text-primary-9-dark, $text-primary-9-dark),
  --background-primary: ($background-primary, $background-primary-dark),
  --background-secondary: ($background-secondary, $background-secondary-dark),
  --background-gray: ($background-gray, $background-primary-dark),
  --text-sidebar-highlight: ($text-sidebar-highlight, $text-sidebar-highlight-dark),
  --background-sidebar: ($background-sidebar, $background-sidebar-dark),
  --background-sidebar-sidebar: ($background-sidebar-filter, $background-sidebar-dark),
  --text-sidebar-background-highlight: ($text-sidebar-background-highlight, $text-sidebar-background-highlight-dark),
  --purple: ($purple, $purple-dark),
  --button-purple: ($button-purple, $button-purple-dark),
  --button-white: ($button-white, $button-white),
  --purple-inverted: ($purple-dark, $purple),
  --purple-rose: ($purple-rose, $purple-primary),
  --purple-rose-inverted: ($purple, $purple-rose),
  --border: ($border, $border-dark),
  --input-text: ($input-text, $input-text),
  --input-background: ($input-background, $input-background),
  --text-primary-opacity-5: ($text-primary-opacity-5, $text-primary-opacity-5-dark),
  --text-primary-opacity-7: ($text-primary-opacity-7, $text-primary-opacity-7-dark),
  --text-primary-opacity-9: ($text-primary-opacity-9, $text-primary-opacity-9-dark),
  --text-black-opacity-0-42: ($text-primary-opacity-5, $text-primary-opacity-5-dark),
  --text-black-opacity-7: ($ionfi-black-opacity-7, $text-primary-opacity-7-dark),
  --text-black-opacity-9: ($ionfi-black-opacity-9, $text-primary-opacity-7-dark),
  --upload-background: ($upload-background, $upload-background-dark),
  --upload-background-hover: ($upload-background-hover, $upload-background-hover-dark),
  --download-button-text: ($download-button-text, $download-button-text-dark),
  --download-button-background: ($download-button-background, $download-button-background-dark),
  --download-button-background-focused: ($download-button-background-focused, $download-button-background-focused-dark),
  --text-green: ($text-green, $text-green),
  --text-red: ($text-red, $text-red),
  --text-yellow: ($color-yellow, $color-yellow)
);
