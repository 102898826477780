.login-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  text-align: center;
  color: #fff;
  .col-lg-4 {
    padding: 0;
  }
  .left-bg {
    background: var(--background-sidebar-sidebar);
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    flex: 1;
    height: 100%;
  }
  .input-lg {
    height: 46px !important;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 0;
  }
  .inline-block {
    display: inline-block;
  }

  .header-font {
    font-size: 27.2165px;
  }

  .custom-select_arrow-ionfi fa-icon {
    margin-top: -34px !important;
  }

  .circle {
    border-radius: 50%;
    padding: 10px;
    font-size: 20px;
  }

  .full-height {
    height: calc(100vh - 56px) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    flex: 1;
  }
  .input-underline {
    background: 0 0;
    border: none;
    box-shadow: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    border-radius: 0;
  }
  .input-underline:focus {
    border-bottom: 2px solid #fff;
    box-shadow: none;
  }
  .rounded-btn {
    border-radius: 4px;
    background-color: var(--purple);
    color: #FFFFFF;
    font-size: 14px;
    line-height: 40px;
    padding: 0 25px;
  }
  //.rounded-btn:hover,
  //.rounded-btn:focus,
  //.rounded-btn:active,
  //.rounded-btn:visited {
  //  color: rgba(255, 255, 255, 1);
  //  border: 2px solid rgba(255, 255, 255, 1);
  //  outline: none;
  //}

  .right-corner-button {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    line-height: 40px;
    padding: 0 25px;
  }
  .right-corner-button:hover,
  .right-corner-button:focus,
  .right-corner-button:active,
  .right-corner-button:visited {
    color: rgba(255, 255, 255, 1);
    outline: none;
  }

  h1 {
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 36px;
    small {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .form-group {
    padding: 8px 0;
    input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.6) !important;
    }

    input:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(255, 255, 255, 0.6) !important;
    }

    input::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.6) !important;
    }

    input:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.6) !important;
    }
  }
  .user-avatar {
    width: 60%;
    //-webkit-border-radius: 50%;
    //border-radius: 50%;
    //border: 2px solid #fff;
  }
  .form-content {
    padding-bottom: 40px;
  }
  .image-container {
    width: 200px;
  }
  option {
    background-color: #fff;
    color: #000;
  }
  .recaptcha-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
