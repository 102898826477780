.table-class {
  #table-header {
    border-collapse: collapse;
    width: 100%;
    overflow-wrap: anywhere;
  }

  #table-body td {
    padding: 20px 0 20px 0;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    border-radius: 0;
    color: var(--text-primary);
    border-left: 0;
    border-right: 0;
    overflow-wrap: anywhere;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
  }

  .table-cs .table-resp-mini {
    text-align: right !important;
  }

  #table-body tr:nth-child(1) td {
    border-top: 0;
  }

  #table-body td a {
    text-transform: none;
    text-decoration: none;
  }

  #table-body tr.cursor-pointer td{
    cursor: pointer;
  }

  #table-header th {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    padding: 8px 0 8px 0;
  }

  #table-header th:nth-child(1){
    padding: 0px 0 0px 30px;
  }

  .hr-table {
    padding: 0;
    margin: 0 0 0 10px;
  }

  #table-body {
    border-collapse: collapse;
    width: 100%;
  }

  @media only screen and (max-width: 300px) {
    .table-cs .nav-menu {
      display: block !important;
      text-align: center !important;
    }
    .table-cs .left-menu {
      float: none !important;
      padding: 0 !important;
    }
    .table-cs .search-button {
      padding: 0 0 0 10px !important;
    }

    .table-cs .sort-button {
      padding: 0 10px 0 0 !important;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 767px),
  (min-device-width: 768px) and (max-device-width: 1024px) {

    /* Force table to not be like tables anymore */
    .table-cs table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .table-cs thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .table-cs #table-header {
      display: none;
    }

    .table-cs #table-body td {
      width: 100% !important;
      padding: 20px 0 20px 0 !important;
    }

    .table-cs td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid var(--purple);
      position: relative;
      padding-left: 50%;
      text-align: center;
      justify-content: center;
    }

    .table-cs .table-resp-mini {
      padding-right: 0 !important;
      text-align: center !important;
    }

    td:before {
      padding-right: 10px;
      white-space: nowrap;
    }
  }
}

#table-body td:nth-child(1) {
  padding: 0 0 0 30px;
}
//
// calculate table height
.table-calc-vh {
  .datatable-body {
    height: calc(100vh - 315px) !important;
  }

  &--merge-profiles{
    .datatable-body {
      height: calc(100vh - 600px) !important;
    }
  }

  &-no-header{
    .datatable-body {
      height: calc(100vh - 250px) !important;
    }
  }


  &-fi-accActivity {
    .datatable-body {
      height: calc(100vh - 429px) !important;
    }
  }

  &-fi-accActivity-details {
    .datatable-body {
      height: calc(100vh - 520px) !important;
    }
  }


  &--statements{
    .datatable-body {
      height: calc(100vh - 320px) !important;
    }
  }
  &-batches-details {
    .datatable-body {
      height: calc(100vh - 360px) !important;
    }
  }
  &-batches {
    .datatable-body {
      height: calc(100vh - 460px) !important;
    }
  }
  &-accoint-details {
    .datatable-body {
      height: calc(100vh - 520px) !important;
    }
  }
  &-fi-rdc {
    .datatable-body {
      height: calc(100vh - 607px) !important;
    }
  }
  &-admin-rdc {
    .datatable-body {
      height: calc(100vh - 632px) !important;
    }
  }
  &-admin-braid-alerts {
    .datatable-body {
      height: calc(100vh - 315px) !important;
    }
  }
}

// used to remove the header name after the checkbox
.header-name-none {
  .datatable-header-cell-template-wrap {
    .datatable-header-cell-wrapper {
      display: none;
    }
  }
}
