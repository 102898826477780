@import './theme/theme';
@import './theme/colors';

.inner-pages-menu {
  display: flex;
  padding: 0;
  list-style: none;
  transition: 2s all;
  border-bottom: solid 1px var(--border);
  .router-link-active{
    a{
      color: var(--purple);
    }
    border-bottom: 3px var(--purple) solid;
  } li {
    padding: 12px 14px 12px 14px;
  cursor: pointer;
  }
  a {
    color: var(--text-primary);
    text-decoration: none;
    padding: 14px;
  }
  li:hover {
    border-bottom: 3px var(--purple) solid;
  }
}
